import intlTelInput from 'intl-tel-input/build/js/intlTelInputWithUtils'
import { de } from 'intl-tel-input/i18n'

import Block from '../../common.blocks/block'
import { europeanCountriesISO2 } from './countries'

export default class PhoneField extends Block {
	static title = 'phone-field'

	constructor($el) {
		super($el)

		if (this.$el.get(0)) {
			this.phoneFieldTel = intlTelInput(this.$el.get(0), {
				i18n: de,
				onlyCountries: europeanCountriesISO2,
				initialCountry: 'de',
				nationalMode: false,
				separateDialCode: true,
				autoInsertDialCode: true,
				formatOnDisplay: false,
				countrySearch: false,
				useFullscreenPopup: false,
				hiddenInput: () => ({
					phone: 'phone',
					country: 'country_code',
				}),
			})

			this.$el.on('input', () => {
				if (!this.$el.val() || this.phoneFieldTel.isValidNumber()) {
					this.disableErrorMessage()
				} else {
					this.enableErrorMessage(this.$el.data('errorMsg'))
				}
			})
		}
	}

	disableErrorMessage() {
		this.$el.get(0)?.setCustomValidity('')
	}

	enableErrorMessage(msg) {
		this.$el.get(0)?.setCustomValidity(msg)
	}
}
