export const europeanCountriesISO2 = [
	'AL', // Albania
	'AD', // Andorra
	'AM', // Armenia
	'AT', // Austria
	'AZ', // Azerbaijan
	'BY', // Belarus
	'BE', // Belgium
	'BA', // Bosnia and Herzegovina
	'BG', // Bulgaria
	'HR', // Croatia
	'CY', // Cyprus
	'CZ', // Czech Republic
	'DK', // Denmark
	'EE', // Estonia
	'FI', // Finland
	'FR', // France
	'GE', // Georgia
	'DE', // Germany
	'GR', // Greece
	'HU', // Hungary
	'IS', // Iceland
	'IE', // Ireland
	'IT', // Italy
	'XK', // Kosovo
	'LV', // Latvia
	'LI', // Liechtenstein
	'LT', // Lithuania
	'LU', // Luxembourg
	'MT', // Malta
	'MD', // Moldova
	'MC', // Monaco
	'ME', // Montenegro
	'NL', // Netherlands
	'MK', // North Macedonia
	'NO', // Norway
	'PL', // Poland
	'PT', // Portugal
	'RO', // Romania
	'SM', // San Marino
	'RS', // Serbia
	'SK', // Slovakia
	'SI', // Slovenia
	'ES', // Spain
	'SE', // Sweden
	'CH', // Switzerland
	'TR', // Turkey (partially in Europe)
	'UA', // Ukraine
	'GB', // United Kingdom
	'VA', // Vatican City
]
